<!-- 开具发票弹窗 -->
<template>
  <el-dialog
    width="40%"
    center
    :visible="visible"
    :lock-scroll="false"
    :destroy-on-close="true"
    custom-class="ele-dialog-form"
    :title="isUpdate?'开具发票':'开具发票'"
    @update:visible="updateVisible">
    <el-form
      ref="form"
      :model="form"
      label-width="130px">

      <div style="border-bottom: 1px solid #eeeeee;">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 18px;font-weight: 700;color: #3f4155;">开票信息</span>
        </div>
        <el-row :gutter="15" style="margin-top: 15px;">
          <el-col :span="12">
            <el-form-item label="客户名称：">
              <span>{{details.company_name}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开票税号：">
              <span>{{details.tax_number}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="电话号码：">
              <span>{{details.phone}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开户银行：">
              <span>{{details.deposit_bank}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="银行账号：">
              <span>{{details.bank_number}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业地址：">
              <span style="color: #FF9B05;">{{details.enterprise_address}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="24">
            <el-form-item label="邮寄地址：">
              <span style="color: #FF9B05;">{{details.delivery_address}}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </div>

      <div style="margin-top: 20px;">
        <el-row :gutter="15" style="margin-top: 15px;">
          <el-col :span="24">
            <el-form-item label="开票金额：">
              <span>{{data.invoice_member}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <div style="margin-top: 10px;">
          <el-form-item label="上传发票：">
            <el-upload
                class="upload-demo"
                :limit="1"
                :action="action"
                :headers="headers"
                :on-success="successUpload"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                multiple
                :on-exceed="handleExceed"
            >
              <div class="shangchuan" style="margin-left: 20px">
                <el-button class="custom-button">
                  <i class="el-icon-shangchuan" />
                  <span style="vertical-align: middle">点击上传</span>
                </el-button>
              </div>
            </el-upload>
          </el-form-item>
          <!--列表-->
          <!--<div style="margin-top: 20px;">-->
          <!--  <div style="background: #f7f8fa;border-radius: 6px;padding: 10px;">-->
          <!--    <div style="display: flex;align-items: center;justify-content: space-between;">-->
          <!--      <div style="display: flex;align-items: center;">-->
          <!--        <img src="@/assets/images/home/caiwu/icon.png" alt="" style="width: 21px;height: 24px;margin-right: 15px;">-->
          <!--        <span style="font-size: 14px;font-weight: 400;color: #242832;">海信集团有限公司.jpg</span>-->
          <!--      </div>-->
          <!--      <div>-->
          <!--        <img src="@/assets/images/home/caiwu/del.png" alt="" style="width: 11px;height: 11px;cursor: pointer;">-->
          <!--      </div>-->
          <!--    </div>-->
          <!--  </div>-->
          <!--</div>-->

        </div>
      </div>

    </el-form>


    <div slot="footer">
      <el-button
        @click="updateVisible(false)">取消
      </el-button>
      <el-button
        style="margin-left: 20px;"
        type="primary"
        :loading="loading"
        @click="save">确定
      </el-button>
    </div>

  </el-dialog>
</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'
import {invoicebilling, invoiceget_invoice_info, upload_invoice} from "@/api/finance";
export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 上传假数据展示
      fileList: [],

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/finance',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },

      //
      details:{},

    };
  },
  mounted() {
    if (this.data) {
      console.log(this.data)
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }

    this.getDetails();
  },
  methods: {
    //获取详情
    getDetails(){
      invoiceget_invoice_info(this.data.id).then(res => {
        this.details = res.data;
      })
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let data = {
            invoice_picture:this.form.invoice_picture
          }
          invoicebilling(this.data.id,data).then(res => {
            console.log(res)
            this.loading = false;
            if (res.code === 200) {
              this.$message.success(res.msg);
              if (!this.isUpdate) {
                this.form = {};
              }
              this.updateVisible(false);
              this.$emit('done');
            } else {
              this.$message.error(res.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.msg);
          })
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //上传事件
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    //上传成功
    successUpload(file,fileList){
      console.log(file)
      // 上传成功后的列表
      this.form.invoice_picture = file.data.urls;
    },


  }
}
</script>

<style scoped lang="scss">

</style>
